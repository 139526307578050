import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeader = styled.th`
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f4f4f4;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
`;

const DeleteButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;

  &:hover {
    background-color: #e53935;
  }
`;

const MedicationList = ({ medications, handleDeleteMedication }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>{t('Medication Name')}</TableHeader>
          <TableHeader>{t('Dosage')}</TableHeader>
          <TableHeader>{t('Time')}</TableHeader>
          <TableHeader>{t('Start Date')}</TableHeader>
          <TableHeader>{t('End Date')}</TableHeader>
          <TableHeader>{t('Period')}</TableHeader>
          <TableHeader>{t('Actions')}</TableHeader>
        </tr>
      </thead>
      <tbody>
        {medications.map((med, index) => (
          <TableRow key={index}>
            <TableCell>{med.medication_name}</TableCell>
            <TableCell>{med.dosage}</TableCell>
            <TableCell>{med.schedule_time}</TableCell>
            <TableCell>{med.start_date}</TableCell>
            <TableCell>{med.end_date || t('No End Date')}</TableCell>
            <TableCell>{med.period !== 'unset' ? med.period : `${med.custom_period} ${t('days')}`}</TableCell>
            <TableCell><DeleteButton onClick={() => handleDeleteMedication(med.id)}>{t('Delete')}</DeleteButton></TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default MedicationList;
