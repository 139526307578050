import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  ${(props) =>
    props.error &&
    css`
      border-color: red;
      box-shadow: 0 0 5px red;
    `}
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  ${(props) =>
    props.error &&
    css`
      border-color: red;
      box-shadow: 0 0 5px red;
    `}
`;

const Button = styled.button`
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  grid-column: span 2;
  
  &:hover {
    background-color: #45a049;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  grid-column: span 2;
  text-align: center;
`;

const MedicationForm = ({
  medicationName, setMedicationName,
  dosage, setDosage,
  dosageUnit, setDosageUnit,
  scheduleTime, setScheduleTime,
  startDate, setStartDate,
  endDate, setEndDate,
  period, setPeriod,
  customPeriod, setCustomPeriod,
  handleAddMedication
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!medicationName) {
      errors.medicationName = true;
      setError(t('Medication Name cannot be empty'));
    }
    if (!/^\d+$/.test(dosage)) {
      errors.dosage = true;
      setError(t('Dosage must be a number'));
    }
    if (period === 'unset' && !customPeriod) {
      errors.customPeriod = true;
      setError(t('Custom Period (days) is required when Period is unset'));
    }
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setError('');
      handleAddMedication();
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <label>{t('medication_name')}:</label>
      <Input 
        type="text" 
        placeholder={t('medication_name')} 
        value={medicationName} 
        onChange={e => setMedicationName(e.target.value)} 
        error={fieldErrors.medicationName}
      />
      
      <label>{t('dosage')}:</label>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Input 
          type="text" 
          placeholder={t('dosage')} 
          value={dosage} 
          onChange={e => setDosage(e.target.value)} 
          style={{ flex: '2' }} 
          error={fieldErrors.dosage}
        />
        <Select 
          value={dosageUnit} 
          onChange={e => setDosageUnit(e.target.value)} 
          style={{ flex: '1' }}
        >
          <option value="mg">mg</option>
          <option value="g">g</option>
          <option value="ml">ml</option>
          <option value="units">units</option>
        </Select>
      </div>
      
      <label>{t('schedule_time')} (HH:MM):</label>
      <Input 
        type="time" 
        value={scheduleTime} 
        onChange={e => setScheduleTime(e.target.value)} 
        error={fieldErrors.scheduleTime}
      />
      
      <label>{t('start_date')}:</label>
      <Input 
        type="date" 
        value={startDate} 
        onChange={e => setStartDate(e.target.value)} 
      />
      
      <label>{t('end_date')}:</label>
      <Input 
        type="date" 
        value={endDate} 
        onChange={e => setEndDate(e.target.value)} 
      />
      
      <label>{t('period')}:</label>
      <Select 
        value={period} 
        onChange={e => setPeriod(e.target.value)}
        error={fieldErrors.customPeriod && period === 'unset'}
      >
        <option value="unset">{t('unset')}</option>
        <option value="daily">{t('daily')}</option>
        <option value="weekly">{t('weekly')}</option>
        <option value="monthly">{t('monthly')}</option>
      </Select>
      
      <label>{t('custom_period')}:</label>
      <Input 
        type="number" 
        placeholder={t('custom_period')} 
        value={customPeriod} 
        onChange={e => setCustomPeriod(e.target.value)} 
        disabled={period !== 'unset'}
        error={fieldErrors.customPeriod && period === 'unset'}
      />
      
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      <Button type="submit">{t('add_medication')}</Button>
    </Form>
  );
};

export default MedicationForm;
