const API_BASE_URL = 'https://telegram-pillpalbot.seele0oo.workers.dev/api';

async function fetchWithCORS(url, options) {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
    },
  });

  const text = await response.text();
  try {
    const json = JSON.parse(text);
    if (!response.ok) {
      throw new Error(json.error || 'Unknown error');
    }
    return json;
  } catch (error) {
    console.error('Error parsing JSON response:', text);
    throw new Error('Failed to parse JSON response');
  }
}

export async function fetchMedications() {
  return fetchWithCORS(`${API_BASE_URL}/medications`, {
    method: 'GET',
  });
}

export async function addMedication(medication) {
  return fetchWithCORS(`${API_BASE_URL}/add_medication`, {
    method: 'POST',
    body: JSON.stringify(medication),
  });
}

export async function deleteMedication(medicationId) {
  return fetchWithCORS(`${API_BASE_URL}/delete_medication`, {
    method: 'POST',
    body: JSON.stringify({ medicationId }),
  });
}

export async function authenticateTelegramUser(user) {
  return fetchWithCORS(`${API_BASE_URL}/telegram_auth`, {
    method: 'POST',
    body: JSON.stringify(user),
  });
}
