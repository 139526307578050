import React, { useState, useEffect } from 'react';
import { fetchMedications, addMedication, deleteMedication, authenticateTelegramUser } from './api';
import styled from 'styled-components';
import MedicationForm from './components/MedicationForm';
import MedicationList from './components/MedicationList';
import TelegramLogin from './components/TelegramLogin';
import { useTranslation } from 'react-i18next';
import './i18n';

const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled.h1`
  text-align: center;
  color: #4CAF50;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 使其在整个视窗高度内居中 */
`;

function App() {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [medications, setMedications] = useState([]);
  const [medicationName, setMedicationName] = useState('');
  const [dosage, setDosage] = useState('');
  const [dosageUnit, setDosageUnit] = useState('mg');
  const [scheduleTime, setScheduleTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [period, setPeriod] = useState('unset');
  const [customPeriod, setCustomPeriod] = useState('');

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setStartDate(today);

    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchMedications()
        .then(data => setMedications(data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [user]);

  const handleAddMedication = () => {
    const medication = {
      telegramUsername: user.username,
      medicationName: medicationName || '未命名',
      dosage: `${dosage} ${dosageUnit}` || '未定义',
      scheduleTime: scheduleTime || '无时间',
      startDate: startDate || '未定义',
      endDate: endDate || null,
      period: period || '未定义',
      customPeriod: customPeriod || -1
    };

    addMedication(medication)
      .then(() => {
        // Reload the page after successfully adding the medication
        window.location.reload();
      })
      .catch(error => console.error('Error adding medication:', error));
  };

  const handleTelegramResponse = response => {
    authenticateTelegramUser(response)
      .then(data => {
        if (data.message) {
          setUser(response);
          localStorage.setItem('user', JSON.stringify(response));
        } else {
          console.error('Authentication failed');
        }
      })
      .catch(error => console.error('Error during authentication:', error));
  };

  const handleDeleteMedication = id => {
    deleteMedication(id)
      .then(() => setMedications(medications.filter(med => med.id !== id)))
      .catch(error => console.error('Error deleting medication:', error));
  };

  return (
    <Container>
      <Title>{t('medication_reminders')}</Title>
      {!user ? (
        <CenteredDiv>
          <TelegramLogin handleTelegramResponse={handleTelegramResponse} />
        </CenteredDiv>
      ) : (
        <>
          <MedicationForm
            medicationName={medicationName}
            setMedicationName={setMedicationName}
            dosage={dosage}
            setDosage={setDosage}
            dosageUnit={dosageUnit}
            setDosageUnit={setDosageUnit}
            scheduleTime={scheduleTime}
            setScheduleTime={setScheduleTime}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            period={period}
            setPeriod={setPeriod}
            customPeriod={customPeriod}
            setCustomPeriod={setCustomPeriod}
            handleAddMedication={handleAddMedication}
          />
          <MedicationList medications={medications} handleDeleteMedication={handleDeleteMedication} />
        </>
      )}
    </Container>
  );
}

export default App;
