import React, { useEffect } from 'react';

const TelegramLogin = ({ handleTelegramResponse }) => {
  useEffect(() => {
    window.TelegramLoginWidget = {
      dataOnauth: user => handleTelegramResponse(user),
    };
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?15';
    script.setAttribute('data-telegram-login', 'SeelePillPalBot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-request-access', 'write');
    script.setAttribute('data-userpic', 'false');
    script.setAttribute('data-onauth', 'TelegramLoginWidget.dataOnauth(user)');
    script.async = true;
    document.getElementById('telegram-login-container').appendChild(script);
  }, [handleTelegramResponse]);

  return <div id="telegram-login-container"></div>;
};

export default TelegramLogin;
